import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src928099888/src/ConSite/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Empty for now`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "75%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "//stgtestbusiness01.staging.fullslate.com/?view=bare",
        "style": {
          "border": "solid 1px #999999",
          "backgroundColor": "transparent",
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        },
        "frameBorder": "0",
        "allowTransparency": "true"
      }}>{`<a title="Online scheduling" href="https://stgtestbusiness01.staging.fullslate.com/?view=bare">Schedule appointment</a>`}</iframe>{` `}</div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      